import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    toggleItem(event) {
        const targetData = event.target.dataset;
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        const itemCheckbox = Array.from(checkboxes).find((checkbox) => {
            return checkbox.value === targetData.itemId;
        });
        itemCheckbox.click();
        this._resetItemForm(event);
        this.stimulate("EditPolicyReflex#toggle_item", itemCheckbox.checked, targetData.preselected, targetData.itemId, targetData.editable);
    }

    saveItem(event) {
        const targetData = event.currentTarget.dataset;
        if (this.anyAttributeChanged(targetData.itemId)) {
            this.showUndoEdits(targetData.itemId);
            this.hideItemAction(targetData.itemId);
            this.stimulate("EditPolicyReflex#refresh_badge", targetData.itemId, "pending_edits");
        } else {
            this.hideUndoEdits(targetData.itemId);
            this.showItemAction(targetData.itemId);
            this.stimulate("EditPolicyReflex#refresh_badge", targetData.itemId, "reset");
        }
        this.hideItemForm(targetData.itemId);
    }

    async resetItemForm(event) {
        const targetData = this._resetItemForm(event);
        this.stimulate("EditPolicyReflex#refresh_badge", targetData.itemId, "reset");
    }

    _resetItemForm(event) {
        const targetData = event.currentTarget.dataset;
        const itemInputs = document.querySelectorAll(`#item-fields-${targetData.itemId} input`);
        itemInputs.forEach((input) => {
            input.value = input.defaultValue;
            if (input.getAttribute('type') === "checkbox") {
                input.checked = input.defaultChecked;
            }
        });
        const itemSelects = document.querySelectorAll(`#item-fields-${targetData.itemId} select`);
        itemSelects.forEach((select) => select.selectedIndex = Array.from(select.options).findIndex((opt) => opt.defaultSelected));
        this.resetImagesAndDocuments(targetData.itemId);

        this.hideUndoEdits(targetData.itemId);
        this.hideItemForm(targetData.itemId);
        this.showItemAction(targetData.itemId);
        return targetData;
    }

    resetImagesAndDocuments(itemId) {
        document.querySelectorAll(`#item-fields-${itemId} #delete-button`).forEach((button) => {
            if (button.dataset.handle === '') {
                button.click();
            }
        })
    }

    showUndoEdits(itemId) {
        const undoEdits = document.getElementById(`undo-edits-${itemId}`);
        undoEdits.classList.remove('hidden');
    }

    hideUndoEdits(itemId) {
        const undoEdits = document.getElementById(`undo-edits-${itemId}`);
        undoEdits.classList.add('hidden');
    }

    showEditItem(event) {
        const targetData = event.currentTarget.dataset;
        this.showItemForm(targetData.itemId);
    }

    hideItemForm(itemId) {
        const itemForm = document.getElementById(`item-edit-form-${itemId}`);
        if (itemForm != null) {
            itemForm.classList.add('hidden');
        }
    }

    showItemForm(itemId) {
        const itemForm = document.getElementById(`item-edit-form-${itemId}`);
        if (itemForm != null) {
            itemForm.classList.remove('hidden');
        }
    }

    hideItemAction(itemId) {
        const itemAction = document.getElementById(`toggle-item-action-${itemId}`);
        itemAction.classList.add('hidden');
    }

    showItemAction(itemId) {
        const itemAction = document.getElementById(`toggle-item-action-${itemId}`);
        itemAction.classList.remove('hidden');
    }

    anyAttributeChanged(itemId) {
        let changed = false;
        const itemInputs = document.querySelectorAll(`#item-fields-${itemId} input`);
        itemInputs.forEach((input) => {
            if (input.getAttribute('type') === "checkbox") {
                changed = changed || (input.checked !== input.defaultChecked);
            } else {
                changed = changed || (input.value !== input.defaultValue);
            }
        });
        const itemSelects = document.querySelectorAll(`#item-fields-${itemId} select`);
        itemSelects.forEach((select) => {
            const selected = select.selectedIndex;
            const defaultSelected = Math.max(Array.from(select.options).findIndex((opt) => opt.defaultSelected), 0);
            changed = changed || (selected !== defaultSelected)
        });
        return changed;
    }

    updateInput(event) {
        const relatedInputs = document.querySelectorAll(`[id$=-${event.target.value}]`);
        relatedInputs.forEach((relatedInput) => {
            relatedInput.disabled = !(relatedInput.disabled);
        });
    }
}